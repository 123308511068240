.input-range__slider {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem; }
  .input-range__slider:active {
    transform: scale(1.3); }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none; }

.input-range__slider-container {
  transition: left 0.3s ease-out; }

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap; }

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute; }

.input-range__label--min {
  left: 0; }

.input-range__label--max {
  right: 0; }

.input-range__label--value {
  position: absolute;
  top: -1.8rem; }

.input-range__label-container {
  left: -50%;
  position: relative; }
  .input-range__label--max .input-range__label-container {
    left: 50%; }

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out; }
  .input-range--disabled .input-range__track {
    background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #3f51b5; }

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }

/*# sourceMappingURL=index.css.map */
.fileUploader {
	width: 100%;
}

.fileContainer {
	background: #fff;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
	position: relative;
	border-radius: 10px;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 10px auto;
	transition: all 0.3s ease-in;
}

.fileContainer input {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.fileContainer p {
	font-size: 12px;
	margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
	max-width: 300px;
	font-size: 12px;
	color: red;
	text-align: left;
}

.fileContainer .chooseFileButton {
	padding: 6px 23px;
	background: #3f4257;
	border-radius: 30px;
	color: white;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: none;
}

.fileContainer .chooseFileButton:hover {
	background: #545972;
}

.fileContainer .uploadFilesButton {
	padding: 5px 43px;
	background: transparent;
	border-radius: 30px;
	color: #3f4257;
	font-weight: 300;
	font-size: 14px;
	margin: 10px 0;
	transition: all 0.2s ease-in;
	cursor: pointer;
	outline: none;
	border: 1px solid #3f4257;
}

.fileContainer .uploadFilesButton:hover {
	background: #3f4257;
	color: #fff;
}

.fileContainer .uploadIcon {
	width: 50px;
	height: 50px;
}

.fileContainer .uploadPicturesWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.fileContainer .uploadPictureContainer {
	width: 25%;
	margin: 5%;
	padding: 10px;
	background: #edf2f6;
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #d0dbe4;
	position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
	width: 100%;
}

.fileContainer .deleteImage {
	position: absolute;
	top: -9px;
	right: -9px;
	color: #fff;
	background: #ff4081;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	font-size: 26px;
	font-weight: bold;
	line-height: 30px;
	width: 30px;
	height: 30px;
}

.flipMove {
	display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

